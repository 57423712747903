import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { ArticleInner } from '../../views/Article/ArticleInner';
import { PhotoGalleryInner } from '../../views/PhotoGallery/PhotoGalleryInner';
import { getFooterPosition } from './utils/getFooterPosition';
import { eventService } from '../../domain/services/eventService';
import { conf } from '../../domain/services/configService';
import { useScroll } from '../../hooks/useScroll';
import {
  getWindowScrollingPercentage,
  isInViewport
} from '../../domain/utils/domUtils';

const styles = {
  container: css`
    background: #fff;
    margin-top: 0;
    clear: both;
  `
};

let previousVisiblePagination;
let mainUrlSearch;

const getSearchReplacement = search => {
  let replacedSearch = search;
  const transporterSettings =
    conf.transporterSettings?.replacements?.split('\n') || [];
  transporterSettings.forEach(expression => {
    if (expression.trim()) {
      const expressionComponents = expression
        .split('||')
        .map(element => element.trim());
      const regularExp = new RegExp(expressionComponents[0]);
      replacedSearch = replacedSearch.replace(
        regularExp,
        expressionComponents[1]
      );
    }
  });
  return replacedSearch;
};

export const TransporterRenderer = ({ content, scrollingSet }) => {
  const [scrollingDepth, setScrollingDepth] = useState(0);
  const refs = scrollingSet.map(() => useRef());

  const updateHistory = pagination => {
    const json = pagination === 'main' ? content : scrollingSet[pagination];
    const title = json.metaTags?.title || json.title;
    const url = new URL(json.url.path, document.location.origin);
    if (document.location.search) {
      if (!mainUrlSearch) {
        mainUrlSearch = document.location.search;
      }
      url.search =
        pagination === 'main'
          ? mainUrlSearch
          : getSearchReplacement(document.location.search);
    }

    if (window.location.pathname !== url.pathname) {
      eventService.dispatchEvent(
        'transporterRoutePreUpdate',
        { content },
        { addFlag: false }
      );
      document.title = title;
      window.history.pushState({}, title, url.href);
      document.dispatchEvent(new Event('gatsbyOnRouteUpdate'));
    }
  };

  const scrollCallback = () => {
    // End of page reached, set a higher scrollingDepth
    if (
      scrollingDepth < scrollingSet.length - 1 &&
      window.innerHeight + window.scrollY >= getFooterPosition()
    ) {
      setScrollingDepth(scrollingDepth + 1);
    }

    // Set history accordingly to scroll.
    let visiblePagination;
    refs.forEach((ref, index) => {
      if (ref.current && isInViewport(ref.current)) {
        visiblePagination = index;
      }
    });
    // If pagination is not one of the scrollingRefs, calculate
    // if we are at the top or at the bottom of the page
    if (visiblePagination === undefined) {
      const windowScrollingPercentage = getWindowScrollingPercentage();
      if (windowScrollingPercentage < 50) {
        visiblePagination = 'main';
      } else {
        visiblePagination = scrollingDepth;
      }
    }

    if (previousVisiblePagination !== visiblePagination) {
      previousVisiblePagination = visiblePagination;
      updateHistory(visiblePagination);
    }
  };

  useScroll(null, scrollCallback, { deps: scrollingDepth });

  const scrollingDivs = scrollingSet.map((json, index) => {
    if (index <= scrollingDepth) {
      const Component =
        json.bundle === 'photo_gallery' ? PhotoGalleryInner : ArticleInner;
      return (
        <div key={`transp-${json.id}`} css={styles.container} ref={refs[index]}>
          <Component content={json} isTransported />
        </div>
      );
    }
    return null;
  });

  return scrollingDivs;
};

TransporterRenderer.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  scrollingSet: PropTypes.arrayOf(PropTypes.any).isRequired
};
