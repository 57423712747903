import React, { useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { conf } from '../../domain/services/configService';
import { useDoesWindowScrollMeetsCriteria } from '../../hooks/useDoesWindowScrollMeetsCriteria';
import { apiClientService } from '../../domain/services/apiClientService';
import { getRandomIntInclusive } from '../../domain/utils/numberUtils';
import { eventService } from '../../domain/services/eventService';
import { resourceService } from '../../domain/services/resourceService';
import { sharedMemory } from '../../domain/services/sharedMemoryService';
import { Loading } from '../responsive/atoms/Loading';
import { theme } from '../../domain/theme';
import { domainService } from '../../domain/services/domainService';
import { TransporterInner } from './TransporterInner';

const styles = {
  loader: css`
    padding: ${theme.spacing.parse('$xl 0')};
  `
};

const numOfScrollingItems = 4;

// Criteria for useDoesWindowScrollMeetsCriteria should be a constant
// function to avoid passing a new function every time this components re-renders.
// Trigger callback immediately after user starts scrolling.
const criteria = () => window.scrollY > 0;

export const Transporter = ({ content }) => {
  const [scrollingSet, setScrollingSet] = useState(undefined);
  const fetchingStarted = useRef(false);

  const fetchScrollingSet = async () => {
    fetchingStarted.current = true;
    const isLocalGatsbyDevDomain = domainService.isLocalGatsbyDevDomain(
      window.location.hostname
    );
    const domain =
      conf.isStage || isLocalGatsbyDevDomain
        ? conf.s3BucketPublicDomain
        : conf.canonicalDomain;
    const domainPart =
      domainService.isRunningOnCMS() || isLocalGatsbyDevDomain
        ? `//${domain}`
        : '';
    const { response } = await apiClientService.getJson(
      `${domainPart}/api/content/latest-contents-by-ptt.json`
    );
    if (response && Object.keys(response).length > 0) {
      const ptt = content.primaryTaxonomy?.entity?.tid;
      const pathsByPtt = response[ptt] || Object.values(response)[0];
      const filteredPathsByPtt = pathsByPtt.filter(path => {
        return path !== content.url.path;
      });
      const start = getRandomIntInclusive(0, filteredPathsByPtt.length);
      const pathSet = filteredPathsByPtt
        .slice(start, start + numOfScrollingItems)
        .concat(filteredPathsByPtt.slice(0, numOfScrollingItems))
        .slice(0, numOfScrollingItems);
      if (pathSet.length > 0) {
        // Save paths to avoid repeated items in YMAL.
        const arrayOfPaths = [content.url.path, ...pathSet];
        const transporterPaths =
          sharedMemory.getOrInitializeSetObject('transporter-paths');
        arrayOfPaths.forEach(path => transporterPaths.add(path));
        const promises = pathSet.map(path =>
          resourceService.getJsonForPathname(path)
        );
        const validScrollingSet = [];
        const results = await Promise.allSettled(promises);
        results.forEach(result => {
          if (result.status !== 'rejected' && result.value) {
            const resultPath = result.value.url?.path;
            if (resultPath) {
              validScrollingSet.push(result.value);
              // Save paths to avoid repeated items in YMAL.
              transporterPaths.add(resultPath);
            }
          }
        });

        setScrollingSet(validScrollingSet);

        eventService.dispatchEvent(
          'transporterScrollingSetDefined',
          validScrollingSet,
          {
            addFlag: true
          }
        );
      }
    }
  };

  const needsFetchingScrollingSet = useDoesWindowScrollMeetsCriteria(criteria, {
    once: true
  });

  // Clean up sharedMemory on unmount, before useEffect clean ups run
  useLayoutEffect(
    () => () => {
      sharedMemory.delete('transporter-paths');
      eventService.removeFlag('transporterScrollingSetDefined');
    },
    []
  );

  if (!fetchingStarted.current && needsFetchingScrollingSet) {
    fetchScrollingSet();
  }

  return scrollingSet ? (
    <TransporterInner content={content} scrollingSet={scrollingSet} />
  ) : (
    <div css={styles.loader}>
      <Loading />
    </div>
  );
};

Transporter.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
