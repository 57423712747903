import { useEffect } from 'react';
import { throttle } from '../domain/utils/throttle';
import { debounce } from '../domain/utils/debounce';

export const useScroll = (
  element,
  callback,
  options = {
    throttleWait: 0,
    debounceWait: 0,
    elementInitiator: null,
    deps: null
  }
) => {
  let handleEvent = callback;
  if (options?.throttleWait) {
    handleEvent = throttle(callback, options.throttleWait);
  }
  if (options?.debounceWait) {
    handleEvent = debounce(callback, options.debounceWait, false);
  }

  useEffect(() => {
    let scrollableElement = element?.current || element || window;
    scrollableElement = options?.elementInitiator
      ? options.elementInitiator(scrollableElement)
      : scrollableElement;

    scrollableElement.addEventListener('scroll', handleEvent, {
      passive: true
    });
    scrollableElement.addEventListener('resize', handleEvent, {
      passive: true
    });

    return () => {
      scrollableElement.removeEventListener('scroll', handleEvent, {
        passive: true
      });
      scrollableElement.removeEventListener('resize', handleEvent, {
        passive: true
      });
    };
  }, [options?.deps]);
};
