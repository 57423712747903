import { breakpointService } from '../../../domain/services/breakpointService';

export const getFooterPosition = () => {
  const offset = breakpointService.isHandheld() ? 500 : 1000;
  return (
    window.scrollY +
    document
      .querySelector('#block-nationalgeographicfooterblock')
      .getBoundingClientRect().top -
    offset
  ); // Offset to ensure that next pagination is ready ahead of time.
};
