import React from 'react';
import PropTypes from 'prop-types';
import { useDoesWindowScrollMeetsCriteria } from '../../../hooks/useDoesWindowScrollMeetsCriteria';
import { getFooterPosition } from '../utils/getFooterPosition';
import { TransporterRenderer } from '../TransporterRenderer';

// Criteria for useDoesWindowScrollMeetsCriteria should be a constant
// function to avoid passing a new function every time this components re-renders
const criteria = () =>
  window.innerHeight + window.scrollY >= getFooterPosition();

export const TransporterInner = ({ content, scrollingSet }) => {
  const needsLoading = useDoesWindowScrollMeetsCriteria(criteria, {
    once: true
  });

  return scrollingSet && needsLoading ? (
    <TransporterRenderer content={content} scrollingSet={scrollingSet} />
  ) : (
    <div />
  );
};

TransporterInner.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  scrollingSet: PropTypes.arrayOf(PropTypes.any).isRequired
};
