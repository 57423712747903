export const sharedMemory = new Map();

sharedMemory.getOrInitializeSetObject = key => {
  if (!sharedMemory.get(key)) {
    sharedMemory.set(key, new Set());
  }
  return sharedMemory.get(key);
};

sharedMemory.getOrInitializeMapObject = key => {
  if (!sharedMemory.get(key)) {
    sharedMemory.set(key, new Map());
  }
  return sharedMemory.get(key);
};
